var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageView",
    { attrs: { noSubTitleExpected: "true" } },
    [
      _c("TitleView", [
        _vm._v("À propos de l'application " + _vm._s(_vm.$t("app-name")))
      ]),
      _c("ContentView", [_c("DebugInfoView")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }