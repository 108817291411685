









import { Component, Vue } from "vue-property-decorator"
import DebugInfoView from "@/views/utils/DebugInfoView.vue"
import TitleView from "@/views/commons/TitleView.vue"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
@Component({
  components: { TitleView, PageView, ContentView, DebugInfoView },
})
export default class AboutPage extends Vue {}
