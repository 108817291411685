























































import { Component, Prop, Vue } from "vue-property-decorator"
import { HealthcheckService, HealthCheck } from "@/services/rest/utils/HealthcheckService"

// Minimum delay (in ms) to wait before displaying the new check status
// (do not show it right away so that user sees it is refreshing)
const minimumLoadingAnimationDelay = 500

const healthCheckService = new HealthcheckService()

@Component
export default class HealthCheckIndicator extends Vue {
  @Prop({ required: false }) showDetails: boolean
  healthCheckStatus: HealthCheckStatus = "Pending"
  healthCheck: HealthCheck = new HealthCheck()

  mounted(): void {
    this.sendHealthCheckRequest()
  }

  /**
   * Uses REST APIs to ask servire its status.
   * Updates the healthCheckStatus after a minimal delay (so that user sees the indicator refreshes)
   */
  async sendHealthCheckRequest(): Promise<void> {
    this.healthCheckStatus = "Pending"
    try {
      this.healthCheck = await healthCheckService.healthCheck()
      let newHealthCheckStatus: HealthCheckStatus = "Warning"
      if (this.healthCheck.servicesUp && this.healthCheck.dbConnected) {
        newHealthCheckStatus = "OK"
      }
      this.refreshStatusDelayed(newHealthCheckStatus)
    } catch (e) {
      this.refreshStatusDelayed("KO")
    }
  }

  refreshStatusDelayed(newHealthCheckStatus: HealthCheckStatus): void {
    setTimeout(() => {
      this.healthCheckStatus = newHealthCheckStatus
    }, minimumLoadingAnimationDelay)
  }
}

export type HealthCheckStatus = "Pending" | "OK" | "Warning" | "KO"
