

















import { Component, Prop, Vue } from "vue-property-decorator"
import Constants from "@/services/Constants"

import HealthCheckIndicator from "@/views/utils/HealthCheckIndicator.vue"
import { Settings, SettingsService } from "@/services/rest/utils/SettingsService"

@Component({
  components: {
    HealthCheckIndicator,
  },
})
export default class DebugInfoView extends Vue {
  appVersion?: string = process.env.VUE_APP_VERSION
  projectVersion?: string = process.env.VUE_APP_PROJECT_VERSION
  gitHash?: string = process.env.VUE_APP_GIT_HASH
  gitRevision?: string = process.env.VUE_APP_GIT_REVISION
  serverURL?: string = Constants.apiUrl("info")
  @Prop({ default: "" }) private debugContext: string
  settings = new Settings()

  mounted(): void {
    SettingsService.INSTANCE.getSettings().then((serverSettings) => {
      console.error(serverSettings)
      this.settings = serverSettings
    })
  }
}
