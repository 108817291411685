var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-message",
        { attrs: { type: "is-info", title: _vm.$t("debug-view-name") } },
        [
          _c("ul", [
            _vm.debugContext.length > 0
              ? _c("li", [_vm._v(_vm._s(_vm.debugContext))])
              : _vm._e(),
            _c("li", [_vm._v("App version: " + _vm._s(_vm.appVersion))]),
            _c("li", [
              _vm._v("Project version: " + _vm._s(_vm.projectVersion))
            ]),
            _c("li", [_vm._v("Git revision : " + _vm._s(_vm.gitRevision))]),
            _c("li", [_vm._v("Server url : " + _vm._s(_vm.serverURL))]),
            _c("li", [
              _vm._v(
                "Matomomo config : " +
                  _vm._s(
                    _vm.settings.MATOMO_URL +
                      " - " +
                      _vm.settings.MATOMO_SITE_ID
                  )
              )
            ])
          ]),
          _c("HealthCheckIndicator", { attrs: { showDetails: true } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }