var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "HealthCheck clickable",
      class: {
        "health-loading": _vm.healthCheckStatus === "Pending",
        "health-ok": _vm.healthCheckStatus === "OK",
        "health-warning": _vm.healthCheckStatus === "Warning",
        "health-ko": _vm.healthCheckStatus === "KO"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "health-text",
          on: { click: _vm.sendHealthCheckRequest }
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.healthCheckStatus === "Pending",
                  expression: "healthCheckStatus === 'Pending'"
                }
              ]
            },
            [
              _c("i", {
                staticClass: "fas fa-spinner fa-spin",
                attrs: {
                  "aria-hidden": "true",
                  title: _vm.$t("health-check-pending")
                }
              }),
              _c("span", { staticClass: "fa-sr-only" }, [
                _vm._v(_vm._s(_vm.$t("health-check-pending")))
              ])
            ]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.healthCheckStatus === "OK",
                  expression: "healthCheckStatus === 'OK'"
                }
              ]
            },
            [
              _c("i", {
                staticClass: "fas fa-wifi",
                attrs: {
                  "aria-hidden": "true",
                  title: _vm.$t("health-check-ok")
                }
              }),
              _c("span", { staticClass: "fa-sr-only" }, [
                _vm._v(_vm._s(_vm.$t("health-check-ok")))
              ])
            ]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.healthCheckStatus === "Warning",
                  expression: "healthCheckStatus === 'Warning'"
                }
              ]
            },
            [
              _c("i", {
                staticClass: "fas fa-wifi",
                attrs: {
                  "aria-hidden": "true",
                  title: _vm.$t("health-check-warning")
                }
              }),
              _c("span", { staticClass: "fa-sr-only" }, [
                _vm._v(_vm._s(_vm.$t("health-check-warning")))
              ])
            ]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.healthCheckStatus === "KO",
                  expression: "healthCheckStatus === 'KO'"
                }
              ]
            },
            [
              _c("i", {
                staticClass: "fas fa-wifi",
                attrs: {
                  "aria-hidden": "true",
                  title: _vm.$t("health-check-ko")
                }
              }),
              _c("span", { staticClass: "fa-sr-only" }, [
                _vm._v(_vm._s(_vm.$t("health-check-ko")))
              ])
            ]
          )
        ]
      ),
      _vm.showDetails
        ? _c("div", { staticClass: "health-details" }, [
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("health-check-version")) +
                  " : " +
                  _vm._s(_vm.healthCheck.version) +
                  " "
              )
            ]),
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("health-check-services-up")) +
                  " : " +
                  _vm._s(_vm.healthCheck.servicesUp)
              )
            ]),
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("health-check-db-connected")) +
                  " : " +
                  _vm._s(_vm.healthCheck.dbConnected) +
                  " "
              )
            ]),
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("health-check-build-date")) +
                  " : " +
                  _vm._s(_vm.healthCheck.buildDate) +
                  " "
              )
            ]),
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("health-check-git-revision")) +
                  " : " +
                  _vm._s(_vm.healthCheck.gitRevision) +
                  " "
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }